// change the theme
$theme-colors: (
        "primary": #FF8A00,
        "danger": #ff4136,
        "secondary": #212121,
        "info": #FFF7DF,
);

$body-color: #5C5C5C;

$btn-border-radius: 5px;
$font-family-base: 'Poppins';

$card-border-radius: 10px;
$card-border-color: #EFEFEF;

$breadcrumb-bg: 'transparent';

$navbar-light-toggler-border-color: 'transparent';

// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';