a {
    color: inherit;
    text-decoration: none;
}

.container-fluid.main-container {
    /*padding-top: 78px;*/
    padding-left: 0;
    padding-right: 0;
}

.container.main-container {
    /*padding-top: 78px;*/
    padding-left: 0;
    padding-right: 0;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}

code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

@media (min-width: 576px) {
    .logo {
        height: 52px;
        width: 172px;
    }
}

@media (max-width: 575px) {
    .logo {
        height: 40px;
        width: 130px;
    }
}



html,
body {
    color: #5C5C5C;
    padding: 0;
    margin: 0;
    font-family: -apple-system, Poppins, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

* {
    box-sizing: border-box;
}

.course__photo {
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.course__head-row {
    padding-top: 20px;
    padding-bottom: 20px;
}

.course__not-logged-alert {
    color: #FF8A00;
    font-size: 12px;
    font-weight: bold;
}

.course__not-logged-alert-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.text-white {
    color: #fff !important;
}

.course-category__badge {
    padding: 5px;
    margin: 5px;
}

.hr--divider {
    margin-bottom: 30px;
    margin-top: 30px;
}

.hr--no-margin {
    margin-bottom: 0;
    margin-top: 0;
}

.content-card__card {
    margin-bottom: 30px;
}

.as-link {
    cursor: pointer;
}

.content-card__text {
    margin-top: 30px;
}

.content-card__video {
}

.course__modules-list {
    margin: 5px;
}

.course-menu__module {
    cursor: pointer;
    font-weight: bold;
}

.course-menu__section {
    cursor: pointer;
    padding: 1rem 0.25rem 1rem 0.25rem;
}

.course-menu__submodule {
    min-height: 50px;
    cursor: pointer;
    padding: 1.25rem 1.25rem 1.25rem 1.5rem;
}

.course-menu__submodule--text {
    font-size: 12px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.course-submodule__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.course-menu__availability-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.auth-form__tab-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: pointer;
}

.auth-form__tab-header-active {
    font-weight: bold;
}

.modal-dialog.auth-modal__dialog {
    width: 400px;
    max-width: 90% !important;
}

.auth-dialog__form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert--small {
    font-size: 14px;
}

.user-info__icon {
    width: 20px;
    height: 20px;
}

.user-info__dropdown {
    width: 250px;
    text-align: right;
    padding-right: 20px;
}

.user-info__dropdown-content {
    display: none;
    position: absolute;
    width: 250px;
    z-index: 1;
}

.user-info__dropdown-links {
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.user-info__dropdown-links a {
    color: #5C5C5C;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.user-info__dropdown-links h6 {
    color: #5C5C5C;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.user-info__dropdown-links span {
    color: #5C5C5C;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.user-info__dropdown:hover .user-info__dropdown-content {
    display: block;
}

footer {
    color: #fff;
    background-color: #212121;
    width: 100%;
    height: 280px;
    padding-top: 50px;
    margin-top: 50px;
}

.column-header {
    margin-bottom: 30px;
    font-weight: bold;
}

.link {
    font-size: 15px;
}

.link-container {
    padding-bottom: 5px;
}

.content-container {
    margin-top: 2rem;
}

.admin-course-list__img {
    border-radius: 9px;
}

.admin-course-list__add-button {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.small-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.in-button-icon {
    width: 20px;
    height: 20px;
}

.medium-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.button-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.bold-text {
    font-weight: bold;
}

.admin-course__edit-button {
    width: 30px;
    height: 30px;
}

.admin-course__editor {
    min-height: 300px;
}

.admin-course__editor-wrapper {

}

.course-list__image {
    width: 100px;
    height: 100px;
    background-position: center center;
    background-repeat: no-repeat;
}

.course-list__already_participated_hint {
    font-style: italic;
    font-size: 14px;
    color: #5C5C5C;
}

.course-list__tab-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.course-list__tab-header-active {
    font-weight: bold;
}

.menu-divider {
    padding: 0px;
    margin: 0px;
}

.teacher-completion__user-icon {
    width: 30px;
    height: 30px;
}

.teacher-completion h5, h6 {
    margin-bottom: 0;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.notification__icon {
    width: 15px;
    height: 24px;
}

.notification__icon--small {
    width: 10px;
    height: 16px;
}

.notification__button {
    position: relative;
    display: inline-block;
    margin-right: 20px;
}

.notification__badge {
    position: absolute;
    right: -15px;
    top: -10px;
    background: #FF8A00;
    text-align: center;
    border-radius: 50%;
    color: white;
    padding: 2px 8px;
    font-size: 10px;
}

.notification-clear__button {
    font-size: 10px;
    background: #FF8A00;
    color: white !important;
    padding: 4px !important;
    text-align: center;
    border-radius: 5px;
}

.user-info__username {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    margin: 0;
    vertical-align: text-bottom;
}

.notifications__dropdown {
    width: 250px;
    text-align: right;
    padding-right: 20px;
}

.notifications__dropdown-content {
    display: none;
    position: absolute;
    width: 250px;
    z-index: 1;
}

.notifications__dropdown-links {
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.notifications__dropdown-links a {
    color: #5C5C5C;
    padding: 6px 8px;
    text-decoration: none;
    display: block;
}

.notifications__dropdown-links h6 {
    color: #5C5C5C;
    padding: 6px 8px;
    text-decoration: none;
    display: block;
}

.notifications__dropdown-links span {
    color: #5C5C5C;
    padding: 6px 8px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.notifications__dropdown:hover .notifications__dropdown-content {
    display: block;
}

.notification-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.notification-item__title {
    font-size: 12px;
    font-weight: bold;
}

.notification-item__description {
    font-size: 10px;
}

.notification-item__time {
    font-size: 8px;
    color: gray;
    font-style: italic;
}

.teacher__img {
    width: 40px;
    height: 40px;
}

.teacher--about {
    font-style: italic;
    font-size: 12px;
}


.form__tab-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: pointer;
}

.form__tab-header-active {
    font-weight: bold;
}

.choose-photo__img {
    width: 150px;
    height: 150px;
}

#user-dropdown:after {
    display: none;
}

#nav-right .dropdown-menu {
    right: 0;
    left: auto;
}

#notification-dropdown:after {
    display: none;
}

#nav-right {
    display: flex !important;
}

#nav-right .dropdown-menu {
    right: 0;
    left: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border: 0px;
}

#nav-right a, span {
    color: #5C5C5C;
}

#nav-right .nav-link {
    padding: 0.5rem;
}

.full-height {
    min-height: 100vh !important;
}

@media (min-width: 576px) {
    #left-menu {
        display: none !important;
    }
}

#left-menu .nav-item {
    height: 40px;
    cursor: pointer;
    padding: 1.25rem 1.25rem 1.25rem 1.5rem;
}

.horizontal-scroll {
    flex: 1;
}

.horizontal-scroll .menu-item-wrapper {
    padding-right: 10px;
    user-select: none;
    cursor: pointer;
    border: none;
}

.horizontal-scroll *:focus {
    outline: none;
}

div.scroll-menu {
    overflow: auto;
    white-space: nowrap;
}

div.scroll-menu .scroll-menu-item {
    display: inline-block;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.scroll-menu .scroll-menu-item-wrapper {
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.admin-user-photo {
    width: 80px;
    height: 80px;
}

.x-small-text {
    font-size: 60%;
    font-weight: 400;
}

.small-user-photo {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.discussion-comment-button {
    text-decoration: underline;
    color: #FF8A00;
}

.discussion-new-topic-button {
    text-wrap: none;
    white-space: nowrap;
}

/*mobile menu*/

.menu-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.3s;
}

.menu-overlay-content {
    position: relative;
    width: 100%;
}

.menu-overlay-no-content {
    position: relative;
    top: 30%;
    width: 100%;
    text-align: center;
}

.menu-overlay a {
    padding: 8px 16px 8px 16px;
    text-decoration: none;
    font-size: 16px;
    color: #45B5AA;
    display: block;
    transition: 0.3s;
}

.menu-overlay .menu-closebtn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
}

@media screen and (max-height: 450px) {
    .menu-overlay .menu-closebtn {
        font-size: 30px;
        top: 15px;
        right: 20px;
    }
}

figcaption {
    display: none !important;
}

/*
 * CKEditor 5 (v23.0.0) content styles.
 * Generated on Wed, 30 Sep 2020 10:17:57 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: hsl(0, 0%, 20%);
    background-color: hsl(0, 0%, 97%);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 1em auto;
    /*display: table;*/
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}

@media (max-width: 575px) {
    .ck-content .table table {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}


/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    margin-bottom: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}

/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}

.ck-content a {
    text-decoration: underline;
}

@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
    }

    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none;
    }
}

@media (min-width: 576px) {
    .topic-counters {
        justify-content: flex-end !important;
    }
}

@media (max-width: 575px) {
    .topic-counters {
        justify-content: center !important;
    }
}

.quiz-scale-checkbox input.form-check-input {
    padding: 0;
    margin-left: 0;
}